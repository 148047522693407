<template>
  <div >
    <div class="container">
      <div class="search-title">扫描风险，掌握企业信用</div>
      <div class="search-second-title">基于海量动态数据科学模型分析，为您提供全面的企业信用报告</div>
      <div>
        <el-row class="dis-cen-end">
          <el-col :span="14">
            <div style="position: relative;">
              <el-input class="input-search" placeholder="请输入企业名称关键词" @focus="handleInputFocus" @blur="handleInputBlur" :input-style="isfocus=='blur' ? 'border-color: #DCDFE6;border-bottom-left-radius:4px;height:50px': 'border-color: #DCDFE6;border-bottom-left-radius:0px;height:50px'" v-model="searchVal">
                <template #append><div class="search-btn-to" @click="handleToSearch()">查询</div></template>
              </el-input>
              <div class="look-report-content">查看 <a class="look-report" @click="handleOpenPdf('2')">【升级版企业报告】</a><span class="look-report" @click="handleOpenPdf('1')">【金融版企业报告】</span>模板</div>
              <div  v-show="isfocus == 'focus'" style="background-color: #fff;">
                <div>
                  <ul class="search-condition" v-if="!searchVal && historyList.length > 0 && idToken">
                    <li v-for="(item, index) of historyList" :key="index" @click="handleChooseVal(item)" class="search-condition-li">{{item}}</li>
                    <div v-if="!searchVal && historyList.length > 0" class="btn-del-content">
                      <span class="clear-btn" @click="handleClearHis"><i class="el-icon-delete"></i>清空历史</span>
                    </div>
                  </ul>
                  <ul class="search-condition" v-if="searchVal && searchList.length > 0">
                    <li v-for="(item, index) of searchList" :key="index" @click="handleChooseVal(item)" class="search-condition-li">{{item}}</li>
                  </ul>
                </div>
                <div v-if="!idToken && !searchVal" class="search-condition" style="padding: 20px 0;">
                  <p style="font-size: 18px;margin-bottom: 20px;">登陆可查看历史搜索记录</p>
                  <el-button type="primary" @click="loginEmit" style="background-color: #5D6FE9;">立即登录</el-button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { forwardBG } from '@/api/forward'
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  name: 'report-search',
  data () {
    return {
      btnActive: '0',
      isfocus: 'blur',
      isLogin: false,
      searchVal: '',
      historyList: [],
      searchList: []
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  watch: {
    idToken (newVal, old) {
      if (this.searchVal) {
        this.handleToSearch()
      }
    },
    searchVal (newVal, old) {
      const vm = this
      const thisSearchVal = newVal.trim()
      vm.searchList = []
      if (thisSearchVal) {
        // 查询公司模糊
        const data = {
          data: { companyInfo: [{ companyName: thisSearchVal }] },
          dateBackgroundRequestType: 'post',
          dateBackgroundUrl: '/esComplexInfo/getESCompanyInfoListByCompanyKeyword'
        }
        forwardBG(data).then(result => {
          if (result.code === 0) {
            const thisSearchList = []
            const data = result.data ? result.data : []
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                if (thisSearchVal !== data[i].companyName) {
                  thisSearchList.push(data[i].companyName)
                  if (i >= 5) break
                }
              }
              vm.searchList = thisSearchList
            } else {
              vm.getSkyErr(thisSearchVal)
            }
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        vm.searchList = []
      }
    }
  },
  created () {
  },
  methods: {
    getSkyErr (name) {
      const vm = this
      const data = {
        data: {
          keyword: name
        },
        dateBackgroundRequestType: 'get',
        dateBackgroundUrl: '/esComplexInfo/getCompanyByKeyword'
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const thisSearchList = []
          const data = result.data
          for (let i = 0; i < data.length; i++) {
            thisSearchList.push(data[i].companyName)
            if (i >= 5) break
          }
          vm.searchList = thisSearchList
        }
      }).catch(error => {
        console.log(error)
      })
    },
    handleClick: function (item, e) {
      this.btnActive = e
    },
    handleClearHis: function () {
      const arr = []
      const localStorageKey = 'report-search-company'
      localStorage.setItem(localStorageKey, arr)
      this.historyList = []
    },
    handleInputFocus: function (e) {
      const vm = this
      vm.isfocus = e.type
      const localStorageKey = 'report-search-company'
      if (localStorage.getItem(localStorageKey)) {
        vm.historyList = JSON.parse(localStorage.getItem(localStorageKey))
      }
    },
    handleInputBlur: function (event) {
      const vm = this
      setTimeout(function () {
        vm.isfocus = event.type
      }, 200)
    },
    handleChooseVal: function (e) {
      this.searchVal = e
    },
    handleToSearch: function () {
      const vm = this
      if (vm.idToken) {
        vm.setStorage()
        vm.$router.push({
          name: 'report-list',
          query: {
            searchVal: vm.searchVal
          }
        })
      } else {
        vm.loginEmit()
      }
    },
    setStorage: function () {
      const vm = this
      let indexSearchList = []
      const localStorageKey = 'report-search-company'
      if (localStorage.getItem(localStorageKey)) {
        indexSearchList = JSON.parse(localStorage.getItem(localStorageKey))
      }
      if (JSON.stringify(indexSearchList).indexOf(vm.searchVal) === -1) {
        indexSearchList.push(vm.searchVal)
        localStorage.setItem(localStorageKey, JSON.stringify(indexSearchList))
      }
    },
    // 打开pdf
    handleOpenPdf: function (e) {
      if (e === '1') {
        const url = '/finance.pdf'
        window.open(url, '_blank')
      } else {
        // #toolbar=0
        const url = '/upgrade.pdf'
        window.open(url)
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.search-title {
  font-size: 46px;
  color: #fff;
  margin-bottom: 20px;
}
.search-second-title {
  font-size: 16px;
  color: #fff;
  margin-bottom: 50px;
}
.search-btn {
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.search-btn:hover {
  font-weight: 600;
}
.search-btn.active {
  background-color: #fff;
  color: #5C6EE9;
  padding: 1px 5px;
  position: relative;
  .icon-sj {
    position: absolute;
    bottom: -5px;
    margin-left: -5px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
  }
}
/deep/ .el-input-group__append, .el-input-group__prepend {
  background-color: #FC7A1E;
  border: none;
  color: #fff;
  width: 70px;
}
.search-btn-to {
  display: block;
  width: 100%;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
}
.senior-btn {
  width: 100px;
  height: 36px;
  margin-left: 20px;
  vertical-align: text-bottom;
  border: 2px solid #FC7A1E;
  border-radius: 4px;
  line-height: 36px;
  display: block;
  color: #fff;
  cursor: pointer;
}
.look-report-content {
  font-size: 16px;
  color: #CCCCCC;
  text-align: right;
  margin-top: 20px;
  .look-report:hover {
    color: #fff;
  }
}
.search-condition {
  position: absolute;
  z-index: 999;
  top: 50px;
  left: 0;
  background-color: #fff;
  width: calc(100% - 112px);
  border: 1px solid #eee;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .search-condition-li {
    list-style: none;
    padding: 10px 15px;
    height: 20px;
    line-height: 20px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
  }
  .btn-del-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 15px;
    font-size: 12px;
    color: #666;
  }
}
</style>
