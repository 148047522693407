<template>
  <div>
    <el-row>
      <el-col :span="24" style="padding-top: 30px;">
        <div class="container dis-cen-cen">
          <el-divider content-position="center" style="width: 1000px;">
            <p style="font-size: 24px;color: #333333;background-color: #F1F2F7; padding: 0 20px;">科学模型分析，构建信用报告</p>
          </el-divider>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 50px;">
      <el-col :span="24" style="padding: 50px 0;">
        <div class="container dis-cen-cen">
          <el-image :src="bg" draggable="false" fit="cover"></el-image>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'report-propaganda',
  components: {},
  data () {
    return {
      bg: require('../../assets/report/propaganda_bg.jpg')
    }
  },
  created () {},
  methods: {}
}
</script>

<style scoped="scoped">
</style>
