<template>
  <div>
    <div class="img-content">
      <el-image style="height: 600px;width: 100%;" draggable="false" fit="fill" :src="bg"></el-image>
      <report-search @loginEmit="loginEmit" class="index-search-content"></report-search>
    </div>
    <report-propaganda v-if="!islogin"></report-propaganda>
    <report-reportdownload v-if="islogin" ref="reportdownloadRef"></report-reportdownload>
  </div>
</template>

<script>
import ReportSearch from '@/components/report-search/report-search'
import ReportPropaganda from '@/components/report-propaganda/report-propaganda'
import ReportReportdownload from '@/components/report-propaganda/report-reportdownload'
import { mapGetters } from 'vuex'
export default {
  name: 'report',
  components: { ReportSearch, ReportPropaganda, ReportReportdownload },
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  data () {
    return {
      islogin: false,
      bg: require('../../assets/index/bg.png')
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  created () {
    if (this.idToken) {
      this.islogin = true
    } else {
      this.islogin = false
    }
  },
  watch: {
    idToken: {
      handler (val, oldval) {
        if (val) {
          const vm = this
          vm.islogin = true
        }
      }
    }
  },
  methods: {}
}
</script>

<style scoped="scoped" lang="less">
.img-content {
  margin-top: -60px;
  min-width: 1200px;
  position: relative;
  .index-search-content {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -600px;
  }
}
</style>
