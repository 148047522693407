<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="container">
          <div class="report-name">我的报告</div>
          <div class="report-down-content dis-bet-cen">
            <div class="report-li" v-for="(item, index) of list" :key="index">
              <div style="height: 100%;" v-if="item.reportType == '00120001'" >
                <div style="position: absolute;top: 15px;left: 30px;width: 70%;height: 90%;border-radius: 5px;overflow: hidden;" v-loading="item.status == 0 ? loading : ''" element-loading-text="报告生成中…" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
                <el-image fit="contain" :src="jc" @click="openReport(item)" style="cursor: pointer;" title="打开报告"></el-image>
              </div>
              <div style="height: 100%;" v-if="item.reportType == '00120002'" >
                <div style="position: absolute;top: 15px;left: 30px;width: 70%;height: 90%;border-radius: 5px;overflow: hidden;" v-loading="item.status == 0 ? loading : ''" element-loading-text="报告生成中…" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
                <el-image fit="contain" v-if="item.reportType == '00120002'" :src="sj" @click="openReport(item)" style="cursor: pointer;" title="打开报告"></el-image>
              </div>
              <div v-if="item.reportType == '00120003'" >
                <div style="position: absolute;top: 15px;left: 30px;width: 70%;height: 90%;border-radius: 5px;overflow: hidden;" v-loading="item.status == 0 ? loading : ''" element-loading-text="报告生成中…" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
                <el-image fit="contain" v-if="item.reportType == '00120003'" :src="jr" @click="openReport(item)" style="cursor: pointer;" title="打开报告"></el-image>
              </div>
              <div class="report-li-name" @click="openReport(item)">{{item.companyName}}</div>
              <div class="report-li-type" v-if="item.reportType == '00120001'">基础版企业报告</div>
              <div class="report-li-type" v-else-if="item.reportType == '00120002'">升级版企业报告</div>
              <div class="report-li-type" v-else-if="item.reportType == '00120003'">金融版企业报告</div>
              <span>
                <span>{{item.generateTime.substring(0,16)}}</span>
                <i style="margin-left: 10px;cursor: pointer;" v-if="vipDatas.vipLevel != '00010001' && item.status != 0" @click="downReport(item)" title="下载报告" class="el-icon-download"></i>
              </span>
            </div>
            <el-empty style="width: 100%;" v-if="list.length == 0" :image-size="200"></el-empty>
            <div class="table-page">
              <el-pagination
                background
                hide-on-single-page="true"
                :page-size="10"
                :pager-count="7"
                layout="prev, pager, next"
                v-model:currentPage="page.pageNum"
                @current-change="pageChange"
                :total="page.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getCreditReportByUserId, getDownLoadUrlByReportId, getDownLoadUrlByReportIdPlus, getDownLoadUrlByReportIdPlusFinance, getDownLoadUrlByReportIdOpen } from '@/api/user.js'
import { mapGetters } from 'vuex'
export default {
  name: 'report',
  components: {},
  data () {
    return {
      jc: require('../../assets/report/jc.png'),
      sj: require('../../assets/report/sj.png'),
      jr: require('../../assets/report/jr.png'),
      list: [],
      page: {
        pageNum: 1,
        total: 0
      },
      loading: true,
      userInfoMes: {},
      vipDatas: {}
    }
  },
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo',
      'busId'
    ])
  },
  watch: {
    userInfo: {
      handler (val, oldval) {
        if (val && oldval === null) {
          if (this.userInfo) {
            this.userInfoMes = JSON.parse(this.userInfo)
            this.getMyReport()
          }
        }
      }
    },
    vipData: {
      handler (val, oldval) {
        this.vipDatas = JSON.parse(this.vipData)
      }
    }
  },
  created () {
    if (this.vipData) {
      this.vipDatas = JSON.parse(this.vipData)
    }
    if (this.userInfo) {
      this.userInfoMes = JSON.parse(this.userInfo)
      this.getMyReport()
    }
  },
  methods: {
    getMyReport () {
      const vm = this
      const data = {
        userId: vm.userInfoMes.userId,
        pageNum: vm.page.pageNum,
        pageSize: 10
      }
      getCreditReportByUserId(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          if (res.list.length > 0) {
            if ((res.list[0].status === 0 || res.list[0].status === '0') && vm.page.pageNum === 1) {
              setTimeout(function () {
                vm.getMyReport()
              }, 5000)
            }
          }
          vm.page.total = res.total
          vm.list = []
          vm.list = res.list
        }
      })
    },
    pageChange (e) {
      this.page.pageNum = e
      this.getMyReport()
    },
    downReport (item) {
      console.log(item)
      // 区分报告类型
      let requestMethod = null
      if (item.reportType === '00120002') {
        requestMethod = getDownLoadUrlByReportIdPlus
      } else if (item.reportType === '00120003') {
        requestMethod = getDownLoadUrlByReportIdPlusFinance
      } else if (item.reportType === '00120001') {
        requestMethod = getDownLoadUrlByReportId
      }
      const data = {
        reportId: item.blCreditReportId
      }
      requestMethod(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          console.log(res)
          // const downUrl = res.replace('https://xyf.huachuangiot.com/engineeringCreditService', 'http://192.168.200.233:8199/engineeringCreditService')
          window.location.href = res
        }
      })
    },
    openReport (item) {
      const data = {
        reportId: item.blCreditReportId
      }
      getDownLoadUrlByReportIdOpen(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          // const downUrl = res.replace('https://xyf.huachuangiot.com/engineeringCreditService', 'http://192.168.200.233:8199/engineeringCreditService')
          window.open(res + '&requestType=1', '_blank')
        }
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.report-name {
  font-size: 24px;
  color: #333;
  height: 80px;
  line-height: 80px;
  text-align: left;
}
.report-down-content {
  background-color: #fff;
  padding: 40px 60px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: flex-start;
  .report-li {
    height: 220px;
    width: 20%;
    margin-bottom: 50px;
    position: relative;
    .report-li-name {
      cursor: pointer;
      position: absolute;
      top: 50px;
      width: 120px;
      margin-left: -60px;
      left: 50%;
      z-index: 9;
      font-size: 18px;
      color: #fff;
    }
    .report-li-type {
      position: absolute;
      bottom: 30px;
      left: 50%;
      width: 120px;
      margin-left: -60px;
      color: #fff;
      font-size: 14px;
    }
  }
}
.table-page {
  width: 100%;
  text-align: right;
  margin-top: 20px;
}
</style>
